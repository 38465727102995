@font-face {
  font-family: "Blinker";
  src: url("../font/Blinker/Blinker-Thin.ttf");
  font-weight: 100;
}
@font-face {
  font-family: "Blinker";
  src: url("../font/Blinker/Blinker-ExtraLight.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "Blinker";
  src: url("../font/Blinker/Blinker-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Blinker";
  src: url("../font/Blinker/Blinker-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Blinker";
  src: url("../font/Blinker/Blinker-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Blinker";
  src: url("../font/Blinker/Blinker-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Blinker";
  src: url("../font/Blinker/Blinker-ExtraBold.ttf");
  font-weight: 800;
}
@font-face {
  font-family: "Blinker";
  src: url("../font/Blinker/Blinker-Black.ttf");
  font-weight: 900;
}
@font-face {
  font-family: "BlackHanSans";
  src: url("../font/Black_Han_Sans/BlackHanSans-Regular.ttf");
  font-weight: 400;
}
* {
  margin: 0;
  padding: 0;
}

._rzr0w {
  position: fixed !important;
  z-index: 9999;
}
._rzr0w svg {
  visibility: hidden;
}

*::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar:horizontal {
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #b5b5b5;
  border-radius: 20px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: #f5f5f5;
}

body {
  background-color: #ffffff;
  min-height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
  font-size: 18px;
}
@media (max-width: 1250px) {
  body {
    font-size: 14px;
  }
}
@media (max-width: 1000px) {
  body {
    font-size: 12px;
  }
}
body .header {
  width: 100%;
  border-bottom: solid 1px #b5b5b5;
  box-sizing: border-box;
  font-size: 1.12em;
}
body .header .wrapper {
  width: 67%;
  margin: 0 auto;
  text-align: center;
}
body .header .wrapper .upper {
  height: 110px;
  display: grid;
  align-content: center;
  grid-template-columns: 40% 20% 40%;
  color: rgb(30, 48, 80);
}
@media (max-width: 800px) {
  body .header .wrapper .upper {
    grid-template-columns: 1% 49% 50%;
  }
}
body .header .wrapper .upper svg {
  height: 2em;
  margin: 0 auto;
}
@media (max-width: 600px) {
  body .header .wrapper .upper svg {
    height: 1.5em;
  }
}
body .header .wrapper .upper .search {
  height: 50px;
  width: 100%;
  display: grid;
  align-content: center;
  grid-template-columns: 50px 85%;
  justify-content: start;
  overflow: hidden;
}
body .header .wrapper .upper .search input {
  background-color: transparent;
  border: none;
  font-family: "Blinker", serif;
  font-size: 1.12em;
  width: 100%;
  opacity: 0;
  transition: opacity ease 1s;
  border-bottom: solid 1px #b5b5b5;
}
body .header .wrapper .upper .search input:focus-visible {
  opacity: 1;
  outline: none;
}
body .header .wrapper .upper .logo {
  height: 50px;
}
body .header .wrapper .upper .logo img {
  height: 100px;
  transform: translateY(-25%);
}
body .header .wrapper .upper .icons {
  height: 50px;
  display: grid;
  align-content: center;
  justify-content: end;
  grid-template-columns: repeat(3, 2.5em);
}
body .header .wrapper .upper .icons .i {
  width: 100%;
  position: relative;
  user-select: none;
  cursor: pointer;
}
body .header .wrapper .upper .icons .i.cartIcon .counter {
  background: #8585f2;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  line-height: 18px;
  color: #ffffff;
  font-size: 1em;
  position: absolute;
  bottom: -5px;
  right: 0;
}
body .header .wrapper .upper .icons .i .menu {
  position: absolute;
  width: fit-content;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  border: solid 1px #b5b5b5;
  z-index: 10;
  font-size: 0.8em;
  display: block;
  visibility: hidden;
  background: #ffffff;
}
body .header .wrapper .upper .icons .i .menu a {
  height: 25px;
  width: 100%;
  display: grid;
  align-items: center;
  text-decoration: none;
  color: #000000;
  border-bottom: solid 1px #b5b5b5;
  padding: 5px 10px;
  box-sizing: border-box;
}
body .header .wrapper .upper .icons .i .menu a:last-child {
  border-bottom: none;
}
body .header .wrapper .upper .icons .i .menu a h3 {
  text-align: center;
  width: 100%;
  font-weight: 400;
  font-family: "Blinker", serif;
  font-size: 1em;
  white-space: nowrap;
}
body .header .wrapper .upper .icons .i.menuActive .menu {
  visibility: visible;
}
body .header .wrapper .lower {
  font-family: "Blinker", serif;
  border-top: solid 1px #b5b5b5;
  box-sizing: border-box;
  height: 50px;
  display: grid;
  justify-content: center;
  align-content: center;
}
body .header .wrapper .lower nav > div {
  display: inline-block;
  border: none;
  margin: 0 1em;
  cursor: pointer;
  user-select: none;
}
body .header .wrapper .lower nav > div.selector {
  position: relative;
}
body .header .wrapper .lower nav > div.selector .selection {
  visibility: hidden;
  width: 100%;
  position: absolute;
  top: 100%;
  left: -10px;
  z-index: 10;
  background-color: #ffffff;
  line-height: 30px;
  padding: 0 10px;
}
body .header .wrapper .lower nav > div.selector .selection a {
  border-top: solid 1px #8585f2;
}
body .header .wrapper .lower nav > div.selector .selection a:first-child {
  margin-top: 10px;
}
body .header .wrapper .lower nav > div.selector.active .selection {
  visibility: visible;
}
body .header .wrapper .lower nav > div a {
  display: block;
  text-decoration: none;
  color: black;
  cursor: pointer;
}
body .home .mainCaroussel {
  width: 100%;
  height: 820px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}
@media (max-width: 1250px) {
  body .home .mainCaroussel {
    height: 80vh;
  }
}
body .home .mainCaroussel .filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 10%;
  background-color: #000000;
  z-index: 5;
}
body .home .mainCaroussel .container {
  height: 100%;
  width: 300%;
  position: relative;
  transition: transform linear 1s;
}
body .home .mainCaroussel .container .element {
  width: 33.3333333333%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: inline-block;
}
body .home .mainCaroussel .container .element h4 {
  position: absolute;
  color: #ffffff;
  z-index: 6;
  left: 200px;
  bottom: 120px;
}
body .home .mainCaroussel .container .element h4 pre {
  font-family: "Blinker", serif;
  font-weight: 800;
  font-size: 1.25em;
  text-align: center;
}
@media (max-width: 800px) {
  body .home .mainCaroussel .container .element h4 {
    left: auto;
    width: 100%;
  }
}
body .home .mainCaroussel .container .element:first-of-type h4 {
  bottom: 120px;
  left: auto;
  right: 200px;
}
@media (max-width: 800px) {
  body .home .mainCaroussel .container .element:first-of-type h4 {
    right: auto;
    width: 100%;
  }
}
body .home .mainCaroussel .container .element:last-of-type h4 {
  top: 30px;
  left: 500px;
}
@media (max-width: 800px) {
  body .home .mainCaroussel .container .element:last-of-type h4 {
    left: auto;
    width: 100%;
  }
}
body .home .mainCaroussel .container .element img {
  width: 100vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 1250px) {
  body .home .mainCaroussel .container .element img {
    width: auto;
    height: 100%;
  }
}
body .home .products {
  width: 100%;
}
body .home .products .container {
  width: 67%;
  margin: 0 auto;
}
@media (max-width: 1250px) {
  body .home .products .container {
    width: 100%;
  }
}
body .home .products .container h1 {
  text-align: center;
  width: 100%;
  height: 100px;
  line-height: 100px;
  font-family: "BlackHanSans", serif;
  font-size: 2.7em;
  color: #8585f2;
  margin-bottom: 10px;
}
body .home .products .container h4 {
  width: 100%;
  font-family: "Blinker", serif;
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  line-height: 2.5em;
}
body .home .products .container h5 {
  width: 100%;
  font-family: "Blinker", serif;
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  line-height: 2em;
}
body .home .products .container .cd {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 300px);
  justify-content: space-between;
}
@media (max-width: 1250px) {
  body .home .products .container .cd {
    grid-template-columns: repeat(4, 24%);
    justify-content: space-evenly;
  }
}
@media (max-width: 800px) {
  body .home .products .container .cd {
    grid-template-columns: repeat(2, 45%);
  }
}
body .home .products .container .cd h1 {
  grid-column: span 4;
}
@media (max-width: 800px) {
  body .home .products .container .cd h1 {
    grid-column: span 2;
  }
}
body .home .products .container .cd .element {
  width: 300px;
}
@media (max-width: 1250px) {
  body .home .products .container .cd .element {
    width: 100%;
  }
}
body .home .products .container .cd .element .imageUnit {
  width: 300px;
  height: 300px;
  overflow: hidden;
  position: relative;
  background: #d9d9d9;
}
@media (max-width: 1250px) {
  body .home .products .container .cd .element .imageUnit {
    width: 100%;
  }
}
body .home .products .container .cd .element .imageUnit img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
body .home .products .container .books {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 400px);
  justify-content: space-between;
}
@media (max-width: 1250px) {
  body .home .products .container .books {
    grid-template-columns: repeat(3, 30%);
    justify-content: space-evenly;
  }
}
@media (max-width: 1000px) {
  body .home .products .container .books {
    grid-template-columns: 80%;
  }
}
body .home .products .container .books h1 {
  grid-column: span 3;
}
@media (max-width: 1000px) {
  body .home .products .container .books h1 {
    grid-column: span 1;
  }
}
body .home .products .container .books .element {
  width: 400px;
}
@media (max-width: 1250px) {
  body .home .products .container .books .element {
    width: 100%;
  }
}
body .home .products .container .books .element .imageUnit {
  width: 400px;
  height: 550px;
  overflow: hidden;
  position: relative;
  background: #d9d9d9;
}
@media (max-width: 1250px) {
  body .home .products .container .books .element .imageUnit {
    width: 100%;
    height: 80vh;
  }
}
body .home .products .container .books .element .imageUnit img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
@media (max-width: 1250px) {
  body .home .products .container .books .element .imageUnit img {
    width: auto;
    height: 100%;
  }
}
body .home .products .container .games {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 600px);
  justify-content: space-between;
}
@media (max-width: 1250px) {
  body .home .products .container .games {
    grid-template-columns: 80%;
    justify-content: space-evenly;
  }
}
body .home .products .container .games h1 {
  grid-column: span 2;
}
@media (max-width: 1250px) {
  body .home .products .container .games h1 {
    grid-column: span 1;
  }
}
body .home .products .container .games .element {
  width: 600px;
}
@media (max-width: 1250px) {
  body .home .products .container .games .element {
    width: 100%;
  }
}
body .home .products .container .games .element .imageUnit {
  width: 600px;
  height: 700px;
  overflow: hidden;
  position: relative;
  background: #d9d9d9;
}
@media (max-width: 1250px) {
  body .home .products .container .games .element .imageUnit {
    width: 100%;
    height: 80vh;
  }
}
body .home .products .container .games .element .imageUnit img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
@media (max-width: 1250px) {
  body .home .products .container .games .element .imageUnit img {
    width: auto;
    height: 100%;
  }
}
body .home .pinnedNumbers {
  width: 90%;
  margin: 0 auto;
  padding: 75px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 25px;
  text-align: center;
}
@media (max-width: 1250px) {
  body .home .pinnedNumbers {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 25px;
    font-size: 1.5em;
  }
}
@media (max-width: 800px) {
  body .home .pinnedNumbers {
    grid-template-columns: 1fr;
    font-size: 2em;
  }
}
body .home .pinnedNumbers svg {
  height: 45px;
}
body .home .pinnedNumbers svg path {
  color: #8585f2;
}
body .home .pinnedNumbers h2 {
  margin-top: 15px;
  margin-bottom: 5px;
  font-family: "BlackHanSans", serif;
  font-size: 1em;
}
body .home .pinnedNumbers pre {
  font-family: "Blinker", serif;
  font-size: 0.66em;
}
body .home .newsletter {
  position: relative;
  width: 100%;
  overflow: hidden;
}
body .home .newsletter img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body .home .newsletter .filter {
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 10%;
  z-index: 1;
}
body .home .newsletter .container {
  position: relative;
  z-index: 2;
  width: fit-content;
  background-color: #ffffff;
  margin: 50px 150px 50px auto;
  padding: 20px 15px;
  border-radius: 20px;
}
body .home .newsletter .container h2 {
  color: #8585f2;
  font-family: "BlackHanSans", serif;
  font-size: 1.5em;
  line-height: 2em;
}
body .home .newsletter .container pre {
  font-family: "Blinker", serif;
  font-size: 0.9em;
  line-height: 2em;
}
body .home .newsletter .container input {
  height: 60px;
  width: 100%;
  background-color: #d9d9d9;
  border: none;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 5px 10px;
  margin: 5px 0 10px 0;
  font-family: "Blinker", serif;
  font-size: 0.9em;
}
body .home .newsletter .container button {
  width: 100%;
  height: 50px;
  font-size: 1.5em;
  font-family: "BlackHanSans", serif;
  background-color: #8585f2;
  color: #ffffff;
  border: none;
}
body .product {
  min-height: 60.3vh;
  width: 70%;
  padding: 50px 0;
  margin: 0 auto;
}
@media (max-width: 1250px) {
  body .product {
    width: 100%;
    box-sizing: border-box;
    padding: 50px 10px;
  }
}
body .product .container {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;
  position: relative;
  column-gap: 50px;
}
@media (max-width: 800px) {
  body .product .container {
    grid-template-columns: 90%;
    justify-content: space-around;
  }
}
body .product .container .images {
  height: fit-content;
  position: sticky;
  top: 0;
  width: 100%;
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(2, calc(50% - 12.5px));
  column-gap: 20px;
  row-gap: 20px;
}
body .product .container .images > img {
  grid-column: span 2;
}
body .product .container .images img {
  width: 100%;
}
@media (max-width: 800px) {
  body .product .container .images {
    position: static;
  }
}
body .product .container .infos {
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  padding: 20px 0;
  position: sticky;
  top: 0;
}
@media (max-width: 800px) {
  body .product .container .infos {
    position: static;
  }
}
body .product .container .infos h2 {
  font-family: "BlackHanSans", serif;
  font-size: 2em;
  font-weight: 400;
}
body .product .container .infos .options {
  margin: 20px 0;
  display: grid;
  width: 100%;
  row-gap: 20px;
  grid-template-columns: 100%;
  box-sizing: border-box;
}
body .product .container .infos .options h4 {
  font-size: 1.75em;
  font-family: "Blinker", serif;
  font-weight: 400;
}
body .product .container .infos .options .optionType {
  width: 100%;
}
body .product .container .infos .options .optionType.BOOLEAN {
  height: 50px;
  width: 100%;
  display: grid;
  line-height: 50px;
  grid-template-columns: 50px 1fr;
}
body .product .container .infos .options .optionType.BOOLEAN .option {
  width: 100%;
  text-align: center;
  order: -1;
  position: relative;
}
body .product .container .infos .options .optionType.BOOLEAN .option input[type=checkbox] {
  height: 35px;
  width: 35px;
  border-radius: 5px;
  border-color: #ffffff !important;
  color: #ffffff;
  visibility: hidden;
}
body .product .container .infos .options .optionType.BOOLEAN .option input[type=checkbox]::before {
  visibility: visible;
  content: "";
  display: inline-block;
  width: 35px;
  height: 35px;
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  box-sizing: border-box;
}
body .product .container .infos .options .optionType.BOOLEAN .option input[type=checkbox].checked::before {
  border: 2px solid #8585f2;
}
body .product .container .infos .options .optionType.BOOLEAN .option input[type=checkbox].checked + svg {
  visibility: visible;
}
body .product .container .infos .options .optionType.BOOLEAN .option svg {
  height: 50px;
  width: 50px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 35%;
  left: 55%;
  color: #8585f2;
  visibility: hidden;
  pointer-events: none;
}
body .product .container .infos .options .optionType .option {
  font-size: 1.75em;
  font-family: "Blinker", serif;
  display: inline-block;
  width: 75px;
  height: 75px;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: center;
  line-height: 75px;
  margin-right: 10px;
  overflow: hidden;
}
body .product .container .infos .options .optionType .option.TEXT {
  background: #d9d9d9 !important;
}
body .product .container .infos .options .optionType .option.TEXT.selected {
  background: #8585f2 !important;
}
body .product .container .infos .options .optionType .option.selected {
  border: solid 10px #8585f2;
  line-height: 55px;
  color: #ffffff;
}
body .product .container .infos .options .optionType .option.noStock {
  background: linear-gradient(45deg, #b9b9b9 12.5%, #d9d9d9 12.5%, #d9d9d9 37.5%, #b9b9b9 37.5%, #b9b9b9 62.5%, #d9d9d9 62.5%, #d9d9d9 87.5%, #b9b9b9 87.5%) !important;
  color: #d9d9d9;
}
body .product .container .infos .options .optionType .option.noStock.selected {
  border: none;
  line-height: 75px;
}
body .product .container .infos .quantity {
  background-color: #d9d9d9;
  width: 250px;
  display: grid;
  height: 100px;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-content: center;
  text-align: center;
  font-family: "Blinker", serif;
  font-size: 2em;
  line-height: 100px;
  border-radius: 20px;
}
body .product .container .infos .quantity button {
  background: transparent;
  border: none;
  font-family: "Blinker", serif;
  font-size: 1.5em;
  text-align: left;
}
body .product .container .infos .quantity button:first-of-type {
  text-align: right;
}
body .product .container .infos > button {
  height: 100px;
  width: 100%;
  background: #8585f2;
  color: #ffffff;
  border: none;
  font-family: "BlackHanSans", serif;
  font-size: 1.8em;
  margin-bottom: 40px;
}
body .product .container .infos p {
  font-size: 1.5em;
  font-family: "Blinker", serif;
  margin-bottom: 40px;
}
body .product .container .infos .selector {
  width: 250px;
  height: 75px;
  position: relative;
  z-index: 11;
}
body .product .container .infos .selector .title {
  width: 250px;
  height: 75px;
  display: grid;
  grid-template-columns: 1fr 50px;
  text-align: center;
  justify-content: center;
  line-height: 75px;
  align-items: center;
  border: solid 1px #8585f2;
  box-sizing: border-box;
  border-radius: 20px;
  z-index: 11;
  position: relative;
  background-color: #ffffff;
}
body .product .container .infos .selector .title svg {
  height: 30px;
  width: 30px;
}
body .product .container .infos .selector h4 {
  font-size: 1.5em;
  font-weight: 400;
  font-family: "Blinker", serif;
}
body .product .container .infos .selector .selection {
  position: absolute;
  visibility: hidden;
  left: 0;
  top: 100%;
  z-index: 9;
  width: 250px;
  line-height: 75px;
  text-align: center;
  background-color: #ffffff;
  border: solid 1px #8585f2;
  box-sizing: border-box;
  padding-top: 20px;
  margin-top: -20px;
}
body .product .container .infos .selector .selection h4 {
  padding-right: 50px;
  border-bottom: solid 1px #8585f2;
  box-sizing: border-box;
}
body .product .container .infos .selector.active > .selection {
  visibility: visible;
}
body .productList {
  width: 100%;
  padding-top: 25px;
}
body .productList .container {
  width: 80%;
  position: relative;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
}
@media (max-width: 1250px) {
  body .productList .container {
    width: 100%;
    padding: 0 25px;
  }
}
body .productList .container h1 {
  font-size: 2.1em;
  font-family: "BlackHanSans", serif;
  color: #8585f2;
  height: 75px;
  line-height: 75px;
}
body .productList .container .actionBar {
  width: 100%;
}
body .productList .container .actionBar .selector {
  width: 250px;
  height: 35px;
  position: relative;
  margin: 0 0 0 auto;
  z-index: 11;
}
body .productList .container .actionBar .selector .title {
  width: 250px;
  height: 35px;
  display: grid;
  grid-template-columns: 1fr 50px;
  text-align: center;
  justify-content: center;
  line-height: 35px;
  align-items: center;
  border: solid 1px #8585f2;
  box-sizing: border-box;
  border-radius: 20px;
  z-index: 11;
  position: relative;
  background-color: #ffffff;
}
body .productList .container .actionBar .selector .title svg {
  height: 30px;
  width: 30px;
}
body .productList .container .actionBar .selector h4 {
  font-size: 0.95em;
  font-weight: 400;
  font-family: "Blinker", serif;
}
body .productList .container .actionBar .selector .selection {
  position: absolute;
  visibility: hidden;
  left: 0;
  top: 100%;
  z-index: 9;
  width: 250px;
  line-height: 35px;
  text-align: center;
  background-color: #ffffff;
  border: solid 1px #8585f2;
  box-sizing: border-box;
  padding-top: 20px;
  margin-top: -20px;
  overflow: hidden;
}
body .productList .container .actionBar .selector .selection h4 {
  padding-right: 50px;
  border-bottom: solid 1px #8585f2;
  box-sizing: border-box;
}
body .productList .container .actionBar .selector.active > .selection {
  visibility: visible;
}
body .productList .container .grid {
  margin-top: 25px;
  margin-bottom: 75px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 25px;
  row-gap: 75px;
}
@media (max-width: 1250px) {
  body .productList .container .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 800px) {
  body .productList .container .grid {
    grid-template-columns: 1fr;
  }
}
body .productList .container .grid .productUnit {
  width: 100%;
  display: block;
  overflow: hidden;
  text-decoration: none;
  color: none;
}
body .productList .container .grid .productUnit .pic {
  position: relative;
  width: 100%;
  height: 650px;
  background: #d9d9d9;
  overflow: hidden;
}
body .productList .container .grid .productUnit .pic img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body .productList .container .grid .productUnit .name {
  font-family: "BlackHanSans", serif;
  color: #8585f2;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 50px;
}
body .productList .container .grid .productUnit .price {
  font-family: "Blinker", serif;
  color: #000000;
  font-size: 1.2em;
  font-weight: 400;
}
body .contact {
  display: grid;
  width: 80%;
  margin: 0 auto;
  grid-template-columns: 25% 75%;
  padding: 55px 0;
  box-sizing: border-box;
}
@media (max-width: 1250px) {
  body .contact {
    width: 100%;
    padding: 55px 25px;
  }
}
@media (max-width: 800px) {
  body .contact {
    grid-template-columns: 1fr;
  }
}
body .contact .infos {
  height: 100%;
  border-right: solid 1px #b5b5b5;
  display: grid;
  align-items: center;
}
@media (max-width: 800px) {
  body .contact .infos {
    border-right: none;
    border-bottom: solid 1px #b5b5b5;
    padding: 10px 0;
  }
}
body .contact .infos .grid {
  width: 100%;
  display: grid;
  grid-template-columns: 65px min-content;
  grid-auto-rows: min-content;
  align-items: center;
  row-gap: 25px;
  font-size: 1.2em;
  font-family: "Blinker", serif;
}
@media (max-width: 1250px) {
  body .contact .infos .grid {
    justify-content: center;
  }
}
body .contact .infos .grid svg {
  width: 50px;
  height: 50px;
  color: #8585f2;
}
body .contact .form .container {
  padding: 34.5px 20px;
}
body .contact .form .container .formUnit textarea {
  display: block;
  width: 100%;
  height: 200px;
  box-sizing: border-box;
  padding: 5px 20px;
  border-radius: 5px;
  border: solid 1px #b5b5b5;
  margin-top: 10px;
  font-size: 1.2em;
  resize: none;
  overflow-y: auto;
  overflow-x: none;
}
body .account {
  width: 80%;
  margin: 0 auto;
  min-height: 49.3vh;
  padding: 50px 50px;
  display: grid;
  grid-template-columns: 20% 80%;
  justify-content: center;
  align-items: start;
  position: relative;
  box-sizing: border-box;
}
@media (max-width: 1250px) {
  body .account {
    width: 100%;
    padding: 50px 10px;
  }
}
@media (max-width: 800px) {
  body .account {
    grid-template-columns: 1fr;
  }
}
body .account .accountNav {
  border-right: solid 1px #b5b5b5;
  height: 580px;
  width: 100%;
  display: grid;
  grid-template-rows: 85% 15%;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: sticky;
  top: 50px;
}
@media (max-width: 800px) {
  body .account .accountNav {
    height: min-content;
    grid-template-rows: 1fr;
    grid-template-columns: 75% 25%;
    position: static;
    border-right: none;
    border-bottom: solid 1px #b5b5b5;
  }
}
body .account .accountNav a {
  width: 100%;
  display: block;
  font-family: "Blinker", serif;
  font-size: 1em;
  color: #b5b5b5;
  line-height: 2em;
  text-decoration: none;
  cursor: pointer;
}
body .account .accountNav a.active {
  color: #8585f2;
}
@media (max-width: 800px) {
  body .account .accountNav a {
    display: inline-block;
    width: auto;
    padding: 5px 10px;
  }
}
body .account .accountNav button {
  width: 260px;
  height: 50px;
  border: none;
  background: none;
  position: relative;
  font-family: "Blinker", serif;
  font-size: 1em;
  color: #b5b5b5;
  cursor: pointer;
}
body .account .accountNav button::after {
  content: "";
  height: 1px;
  width: 150px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: solid 1px #b5b5b5;
}
@media (max-width: 800px) {
  body .account .accountNav button {
    width: auto;
    padding: 5px 10px;
  }
  body .account .accountNav button::after {
    visibility: hidden;
  }
}
body .account .form .container {
  padding: 50px 20px;
}
body .account .form .container .formUnit {
  position: relative;
}
body .account .form .container .formUnit h3 {
  font-family: "Blinker", serif;
  font-weight: 400;
  font-size: 1em;
  user-select: none;
}
body .account .form .container .formUnit.BOOLEAN {
  height: 50px;
  width: 100%;
  display: grid;
  line-height: 50px;
  grid-template-columns: 50px 1fr;
  text-align: left;
}
body .account .form .container .formUnit.BOOLEAN .boolContainer {
  width: 100%;
  position: relative;
  order: -1;
  text-align: center;
}
body .account .form .container .formUnit.BOOLEAN .boolContainer input[type=checkbox] {
  height: 35px;
  width: 35px;
  border-radius: 5px;
  border-color: #ffffff !important;
  color: #ffffff;
  visibility: hidden;
}
body .account .form .container .formUnit.BOOLEAN .boolContainer input[type=checkbox]::before {
  visibility: visible;
  content: "";
  display: inline-block;
  width: 35px;
  height: 35px;
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  box-sizing: border-box;
}
body .account .form .container .formUnit.BOOLEAN .boolContainer input[type=checkbox].checked::before {
  border: 2px solid #8585f2;
}
body .account .form .container .formUnit.BOOLEAN .boolContainer input[type=checkbox].checked + svg {
  visibility: visible;
}
body .account .form .container .formUnit.BOOLEAN .boolContainer svg {
  height: 50px;
  width: 50px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 35%;
  left: 55%;
  color: #8585f2;
  visibility: hidden;
  pointer-events: none;
  background: transparent;
}
body .account .orders {
  height: 580px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px 75px;
  box-sizing: border-box;
}
body .account .orders .order {
  height: 75px;
  display: grid;
  grid-template-columns: 28% 28% 28% 16%;
  text-align: center;
  justify-content: center;
  font-family: "Blinker", serif;
  font-weight: 400;
  font-size: 1em;
}
body .account .orders .order .status {
  font-family: "BlackHanSans", serif;
}
body .account .orders .order .status.COMPLETED {
  color: #2fa12f;
}
body .account .orders .order .status.PAID, body .account .orders .order .status.PENDING {
  color: #ffa500;
}
body .account .orders .order .status.CANCELED {
  color: #db0000;
}
body .account .supports {
  height: 580px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px 75px;
  box-sizing: border-box;
}
body .account .supports .support {
  height: 75px;
  display: grid;
  grid-template-columns: 28% 28% 28% 16%;
  text-align: center;
  justify-content: center;
  font-family: "Blinker", serif;
  font-weight: 400;
  font-size: 1em;
}
body .account .supports .support .status {
  font-family: "BlackHanSans", serif;
}
body .account .supports .support .status.PENDING {
  color: #2fa12f;
}
body .account .supports .support .status.ANSWERED {
  color: #ffa500;
}
body .account .supports .support .status.CLOSED {
  color: #b5b5b5;
}
body .supportPage {
  width: 80%;
  margin: 0 auto;
  min-height: 49.3vh;
  height: min-content;
  max-height: 75vh;
  padding: 50px 50px;
  display: grid;
  grid-template-columns: 20% 80%;
  justify-content: center;
  align-items: start;
  position: relative;
  box-sizing: border-box;
}
@media (max-width: 800px) {
  body .supportPage {
    width: 100%;
    max-height: max-content;
    grid-template-columns: 100%;
  }
}
body .supportPage .supportNav {
  border-right: solid 1px #b5b5b5;
  height: 580px;
  width: 100%;
  position: sticky;
  top: 50px;
  z-index: 5;
}
@media (max-width: 800px) {
  body .supportPage .supportNav {
    position: absolute;
    top: 50px;
    left: 0;
  }
}
body .supportPage .supportNav .burger {
  position: absolute;
  top: 50%;
  left: 5px;
  font-size: 2em;
  transform: translateY(-50%);
  visibility: hidden;
}
@media (max-width: 800px) {
  body .supportPage .supportNav .burger {
    visibility: visible;
  }
}
body .supportPage .supportNav .close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2em;
  transform: translateY(-50%);
  visibility: hidden;
}
body .supportPage .supportNav .container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 90% 10%;
  justify-content: center;
  text-align: center;
  background-color: #ffffff;
}
@media (max-width: 800px) {
  body .supportPage .supportNav .container {
    visibility: hidden;
  }
}
body .supportPage .supportNav .container nav {
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  padding-right: 5px;
}
body .supportPage .supportNav .container nav a {
  width: 100%;
  height: 100px;
  display: grid;
  font-family: "Blinker", serif;
  font-size: 1em;
  color: #000000;
  line-height: 2em;
  text-decoration: none;
  cursor: pointer;
  grid-template-rows: 38% 38% 24%;
  box-sizing: border-box;
  border: solid 1px #ffffff;
  border-radius: 5px;
  text-align: left;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 20px;
}
body .supportPage .supportNav .container nav a h3 {
  font-size: 1.2em;
  font-weight: 700;
  font-family: "Blinker", serif;
}
body .supportPage .supportNav .container nav a .small {
  display: grid;
  grid-template-columns: 50% 50%;
}
body .supportPage .supportNav .container nav a .small h6 {
  font-family: "Blinker", serif;
  font-weight: 300;
  font-style: italic;
  font-size: 0.75em;
}
body .supportPage .supportNav .container nav a:hover {
  border-color: #8585f2;
}
body .supportPage .supportNav .container nav a:hover h3 {
  color: #8585f2;
}
body .supportPage .supportNav .container nav a:hover .small h6 {
  color: #8585f2;
}
body .supportPage .supportNav .container nav a.active {
  color: #ffffff;
  background-color: #8585f2;
}
body .supportPage .supportNav .container nav a.active:hover h3 {
  color: #ffffff;
}
body .supportPage .supportNav .container nav a.active:hover .small h6 {
  color: #ffffff;
}
body .supportPage .supportNav .container button {
  width: 260px;
  height: 50px;
  background: none;
  position: relative;
  font-family: "Blinker", serif;
  font-size: 1.6em;
  color: #8585f2;
  cursor: pointer;
  border: solid 1px #8585f2;
  font-weight: 600;
  border-radius: 5px;
  align-self: end;
}
body .supportPage .supportNav .container button:hover {
  background-color: #8585f2;
  color: #ffffff;
}
@media (max-width: 800px) {
  body .supportPage .supportNav.active .container {
    visibility: visible;
  }
  body .supportPage .supportNav.active .close {
    visibility: visible;
  }
  body .supportPage .supportNav.active .burger {
    visibility: hidden;
  }
}
body .supportPage .supportRequest {
  height: 580px;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}
body .supportPage .supportRequest .container {
  height: 100%;
  width: 100%;
  display: grid;
  box-sizing: border-box;
  padding-bottom: 75px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
@media (max-width: 800px) {
  body .supportPage .supportRequest .container {
    grid-template-rows: 1fr min-content;
    padding-bottom: 0;
  }
}
body .supportPage .supportRequest .container h1 {
  grid-row: span 3;
  align-self: center;
  text-align: center;
  color: #b5b5b5;
}
body .supportPage .supportRequest .container .messages {
  height: 107%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: 100%;
  row-gap: 15px;
  padding: 10px 20px 5% 50px;
  box-sizing: border-box;
}
@media (max-width: 800px) {
  body .supportPage .supportRequest .container .messages {
    padding: 10px 0;
  }
}
body .supportPage .supportRequest .container .messages .message {
  height: fit-content;
  max-width: 80%;
  width: fit-content;
  position: relative;
  border: solid 1px #b5b5b5;
  border-radius: 5px;
  padding: 10px 20px;
}
body .supportPage .supportRequest .container .messages .message h5 {
  position: absolute;
  left: 20px;
  top: -8px;
  background: #ffffff;
  padding: 0 5px;
  font-weight: 400;
  font-family: "Blinker", serif;
  font-size: 0.83em;
}
body .supportPage .supportRequest .container .messages .message h6 {
  font-family: "Blinker", serif;
  font-weight: 200;
  text-align: right;
}
body .supportPage .supportRequest .container .messages .message.user {
  border-color: #8585f2;
  justify-self: end;
  text-align: right;
}
body .supportPage .supportRequest .container .messages .message.user h5 {
  left: auto;
  right: 20px;
  color: #8585f2;
}
body .supportPage .supportRequest .container .messages .message.user h6 {
  text-align: left;
}
body .supportPage .supportRequest .container .sendBox {
  position: absolute;
  bottom: 0;
  right: 10px;
  display: grid;
  grid-auto-flow: column;
}
@media (max-width: 800px) {
  body .supportPage .supportRequest .container .sendBox {
    right: 0;
    position: static;
  }
}
body .supportPage .supportRequest .container .sendBox .formUnit {
  height: 85px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding-top: 10px;
}
@media (max-width: 800px) {
  body .supportPage .supportRequest .container .sendBox .formUnit {
    width: auto;
  }
}
body .supportPage .supportRequest .container .sendBox .formUnit textarea {
  display: block;
  width: 350px;
  height: 75px;
  box-sizing: border-box;
  padding: 5px 20px;
  border-radius: 5px;
  border: solid 1px #b5b5b5;
  font-size: 1.2em;
  resize: none;
  overflow-y: auto;
  overflow-x: none;
  outline: none;
  font-family: "Blinker", serif;
}
@media (max-width: 800px) {
  body .supportPage .supportRequest .container .sendBox .formUnit textarea {
    width: 250px;
  }
}
body .supportPage .supportRequest .container .sendBox .formUnit textarea:focus-visible {
  border-color: #8585f2;
  border-width: 2px;
}
body .supportPage .supportRequest .container .sendBox .formUnit h5 {
  position: absolute;
  left: 20px;
  top: 2px;
  background: #ffffff;
  padding: 0 5px;
  font-weight: 400;
  font-family: "Blinker", serif;
  font-size: 0.83em;
}
body .supportPage .supportRequest .container .sendBox button {
  width: 200px;
  height: 75px;
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  font-family: "Blinker", serif;
  font-size: 1.6em;
  background-color: #ffffff;
  color: #8585f2;
  cursor: pointer;
  border: solid 1px #8585f2;
  font-weight: 600;
  border-radius: 5px;
  align-self: end;
}
@media (max-width: 800px) {
  body .supportPage .supportRequest .container .sendBox button {
    width: 100px;
    align-self: auto;
  }
}
body .supportPage .supportRequest .container .sendBox button:hover {
  background-color: #8585f2;
  color: #ffffff;
}
body .supportPage .supportRequest .container .sendBox button .spinner-container {
  height: 100%;
}
body .supportPage .supportRequest .form {
  height: 100%;
  display: grid;
  align-items: center;
}
body .supportPage .supportRequest .form .container {
  width: 450px;
  padding: 0;
  overflow: visible;
  height: fit-content;
}
body .supportPage .supportRequest .form .container .formUnit {
  position: relative;
  box-sizing: border-box;
}
body .supportPage .supportRequest .form .container .formUnit .selector {
  height: 50px;
  position: relative;
  margin-top: 10px;
  border: solid 1px #b5b5b5;
  border-radius: 5px;
  font-size: 1.2em;
}
body .supportPage .supportRequest .form .container .formUnit .selector .title {
  height: 100%;
  line-height: 45px;
  user-select: none;
  cursor: pointer;
}
body .supportPage .supportRequest .form .container .formUnit .selector .selection {
  visibility: hidden;
  width: 100%;
  left: -2px;
  position: absolute;
  top: 100%;
  z-index: 10;
  background-color: #ffffff;
  line-height: 30px;
  border: solid 2px #b5b5b5;
  user-select: none;
  cursor: pointer;
}
body .supportPage .supportRequest .form .container .formUnit .selector .selection div {
  border-top: solid 1px #8585f2;
}
body .supportPage .supportRequest .form .container .formUnit .selector .selection div:first-child {
  border-top: none;
}
body .supportPage .supportRequest .form .container .formUnit .selector.active .selection {
  visibility: visible;
}
body .supportPage .supportRequest .form .container .formUnit input {
  outline: none;
}
body .supportPage .supportRequest .form .container .formUnit input:focus-visible {
  border-color: #8585f2;
  border-width: 1px;
}
body .supportPage .supportRequest .form .container .formUnit textarea {
  display: block;
  margin-top: 10px;
  width: 100%;
  height: 150px;
  box-sizing: border-box;
  padding: 5px 20px;
  border-radius: 5px;
  border: solid 1px #b5b5b5;
  font-size: 1.2em;
  resize: none;
  overflow-y: auto;
  overflow-x: none;
  outline: none;
  font-family: "Blinker", serif;
}
body .supportPage .supportRequest .form .container .formUnit textarea:focus-visible {
  border-color: #8585f2;
  border-width: 1px;
}
body .order {
  width: 100%;
  height: 100%;
  position: relative;
}
body .order .filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #d9d9d9;
  opacity: 0.4;
  z-index: 99;
}
body .order .container {
  position: relative;
  margin: 0 auto;
  z-index: 100;
  background: #ffffff;
  width: 80%;
  height: 100%;
  padding: 0 25px;
  box-sizing: border-box;
}
body .order .container .cartHeader {
  height: 120px;
  width: 100%;
  box-sizing: border-box;
  padding-top: 40px;
  position: relative;
}
body .order .container .cartHeader h1 {
  font-family: "BlackHanSans", serif;
  color: #8585f2;
  font-weight: 400;
}
body .order .container .cartHeader svg {
  position: absolute;
  top: 20px;
  right: 0px;
  height: 40px;
  color: #8585f2;
  cursor: pointer;
}
body .order .container .cartBody {
  height: calc(100% - 240px);
  width: 100%;
  box-sizing: border-box;
  border-top: solid 1px #8585f2;
  border-bottom: solid 1px #8585f2;
  overflow: auto;
  padding: 20px 0;
}
body .order .container .cartBody .productUnit {
  display: grid;
  grid-template-columns: 25% 75%;
  margin-bottom: 30px;
}
body .order .container .cartBody .productUnit .pic {
  width: 100%;
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
}
body .order .container .cartBody .productUnit .pic img {
  width: 100%;
}
body .order .container .cartBody .productUnit .infos {
  width: 100%;
  display: grid;
  grid-template-columns: 75% 25%;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0 0 20px;
}
body .order .container .cartBody .productUnit .infos .nom {
  font-size: 1.2em;
  font-family: "Blinker", serif;
  font-weight: 400;
}
body .order .container .cartBody .productUnit .infos .price {
  font-size: 1.2em;
  font-family: "Blinker", serif;
  font-weight: 400;
  font-style: italic;
}
body .order .container .cartBody .productUnit .infos .subinfo h4 {
  font-size: 1em;
  font-family: "Blinker", serif;
  font-weight: 200;
  font-style: italic;
}
body .order .container .cartBody .productUnit .infos svg {
  color: #8585f2;
  height: 30px;
  justify-self: center;
  cursor: pointer;
}
body .order .container .cartFooter {
  height: 120px;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 80% 20%;
  line-height: 40px;
  align-items: center;
  align-content: start;
  grid-template-rows: min-content;
}
body .order .container .cartFooter h3 {
  font-size: 1.2em;
  font-weight: 400;
  font-family: "Blinker", serif;
}
body .order .container .cartFooter h3:last-of-type {
  text-align: right;
}
body .order .container .cartFooter h3:first-of-type {
  text-align: left;
}
body .order .container .cartFooter .spinner-container {
  max-height: 40px;
}
body .order .container .cartFooter button {
  grid-column: 2 span;
  height: 50px;
  background-color: #8585f2;
  color: #ffffff;
  font-family: "BlackHanSans", serif;
  border: none;
  font-size: 1.5em;
}
body .cart {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  visibility: hidden;
}
body .cart.active {
  visibility: visible;
}
body .cart .filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #d9d9d9;
  opacity: 0.4;
  z-index: 99;
}
body .cart .container {
  position: relative;
  margin: 0 0 0 auto;
  z-index: 100;
  background: #ffffff;
  width: 500px;
  height: 100%;
  padding: 0 25px;
  box-sizing: border-box;
}
body .cart .container .cartHeader {
  height: 120px;
  width: 100%;
  box-sizing: border-box;
  padding-top: 40px;
  position: relative;
}
body .cart .container .cartHeader h1 {
  font-family: "BlackHanSans", serif;
  color: #8585f2;
  font-weight: 400;
}
body .cart .container .cartHeader svg {
  position: absolute;
  top: 20px;
  right: 0px;
  height: 40px;
  color: #8585f2;
  cursor: pointer;
}
body .cart .container .cartBody {
  height: calc(100% - 240px);
  width: 100%;
  box-sizing: border-box;
  border-top: solid 1px #8585f2;
  border-bottom: solid 1px #8585f2;
  overflow: auto;
  padding: 20px 0;
}
body .cart .container .cartBody .productUnit {
  display: grid;
  grid-template-columns: 25% 75%;
  margin-bottom: 30px;
}
body .cart .container .cartBody .productUnit .pic {
  width: 100%;
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
}
body .cart .container .cartBody .productUnit .pic img {
  width: 100%;
}
body .cart .container .cartBody .productUnit .infos {
  width: 100%;
  display: grid;
  grid-template-columns: 75% 25%;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0 0 20px;
}
body .cart .container .cartBody .productUnit .infos .nom {
  font-size: 1.2em;
  font-family: "Blinker", serif;
  font-weight: 400;
}
body .cart .container .cartBody .productUnit .infos .price {
  font-size: 1.2em;
  font-family: "Blinker", serif;
  font-weight: 400;
  font-style: italic;
}
body .cart .container .cartBody .productUnit .infos .subinfo h4 {
  font-size: 1em;
  font-family: "Blinker", serif;
  font-weight: 200;
  font-style: italic;
}
body .cart .container .cartBody .productUnit .infos svg {
  color: #8585f2;
  height: 30px;
  justify-self: center;
  cursor: pointer;
}
body .cart .container .cartFooter {
  height: 120px;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 80% 20%;
  line-height: 40px;
  align-items: center;
  align-content: start;
  grid-template-rows: min-content;
}
body .cart .container .cartFooter h3 {
  font-size: 1.2em;
  font-weight: 400;
  font-family: "Blinker", serif;
}
body .cart .container .cartFooter h3:last-of-type {
  text-align: right;
}
body .cart .container .cartFooter h3:first-of-type {
  text-align: left;
}
body .cart .container .cartFooter .spinner-container {
  max-height: 40px;
}
body .cart .container .cartFooter button {
  grid-column: 2 span;
  height: 50px;
  background-color: #8585f2;
  color: #ffffff;
  font-family: "BlackHanSans", serif;
  border: none;
  font-size: 1.5em;
}
body .form {
  width: 100%;
  text-align: center;
}
body .form .container {
  width: 500px;
  margin: 0 auto;
  display: grid;
  row-gap: 25px;
  box-sizing: border-box;
  padding: 150px 20px;
}
body .form .container h1 {
  font-family: "BlackHanSans", serif;
  font-weight: 400;
  color: #8585f2;
  line-height: 50px;
  font-size: 2.3em;
}
body .form .container .formUnit {
  width: 100%;
  position: relative;
}
body .form .container .formUnit input {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  padding: 5px 20px;
  border-radius: 5px;
  border: solid 1px #b5b5b5;
  margin-top: 10px;
  font-size: 1.2em;
  font-family: "Blinker", serif;
}
body .form .container .formUnit h5 {
  position: absolute;
  left: 20px;
  top: 2px;
  background: #ffffff;
  padding: 0 5px;
  font-weight: 400;
  font-family: "Blinker", serif;
  font-size: 0.83em;
}
body .form .container .formUnit h6 {
  font-weight: 400;
  font-size: 0.75em;
  text-align: left;
}
body .form .container .formUnit.small {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: left;
  justify-content: start;
  align-items: start;
}
body .form .container .formUnit.small h6 {
  font-weight: 400;
  font-size: 0.75em;
}
body .form .container .formUnit.small > *:last-child {
  text-align: end;
  color: #000000;
  font-weight: 400;
}
body .form .container button {
  height: 50px;
  line-height: 50px;
  font-family: "Blinker", serif;
  font-weight: 600;
  font-size: 1.5em;
  background-color: transparent;
  color: #8585f2;
  border: solid 1px #8585f2;
  border-radius: 5px;
  user-select: none;
  cursor: pointer;
}
body .form .container button:hover {
  background-color: #8585f2;
  color: #ffffff;
}
body .preferences .cartBody {
  display: grid;
  grid-template-rows: calc((100% - 2px) / 2) 2px calc((100% - 2px) / 2);
}
body .preferences .cartBody .languages {
  height: 100%;
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
  overflow: auto;
  display: grid;
  row-gap: 15px;
  column-gap: 15px;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(4, 1fr);
}
body .preferences .cartBody .languages .localeUnit {
  width: 100px;
  height: 50px;
  box-sizing: border-box;
  border: solid 1px #8585f2;
  border-radius: 5px;
  padding: 7px 5px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 55% 45%;
  align-items: center;
  text-align: center;
  font-size: 1.5em;
  font-family: "Blinker", serif;
  user-select: none;
}
body .preferences .cartBody .languages .localeUnit h4 {
  font-weight: 400;
  line-height: 34px;
}
body .preferences .cartBody .languages .localeUnit img {
  width: 100%;
  height: 100%;
}
body .preferences .cartBody .languages .localeUnit.active {
  background-color: #8585f2;
  font-family: "BlackHanSans", serif;
  color: #ffffff;
  line-height: 34px;
}
body .preferences .cartBody .divide {
  border-bottom: solid 1px #8585f2;
}
body .preferences .cartBody .currencies {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
  overflow: auto;
  display: grid;
  row-gap: 15px;
  column-gap: 15px;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(4, 1fr);
}
body .preferences .cartBody .currencies .currencyUnit {
  width: 100px;
  height: 50px;
  box-sizing: border-box;
  border: solid 1px #8585f2;
  border-radius: 5px;
  padding: 7px 5px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 25% 75%;
  align-items: center;
  text-align: center;
  font-size: 1.5em;
  font-family: "Blinker", serif;
  user-select: none;
}
body .preferences .cartBody .currencies .currencyUnit h4 {
  font-weight: 400;
  line-height: 34px;
}
body .preferences .cartBody .currencies .currencyUnit.active {
  background-color: #8585f2;
  font-family: "BlackHanSans", serif;
  color: #ffffff;
  line-height: 34px;
}
body .preferences .cartFooter {
  padding-top: 40px;
}
body .footer {
  background-color: #131313;
  color: #ffffff;
  text-align: center;
}
body .footer h2 {
  color: #8585f2;
  font-family: "BlackHanSans", serif;
  font-size: 1em;
  font-weight: 400;
}
body .footer h2 a {
  color: #8585f2;
  text-decoration: none;
}
body .footer .container {
  padding: 10px 50px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  font-family: "Blinker", serif;
  font-size: 1em;
  line-height: 1.5em;
}
@media (max-width: 800px) {
  body .footer .container {
    grid-template-columns: 80%;
    row-gap: 25px;
    justify-content: space-around;
  }
}
body .footer .container h2 {
  margin-bottom: 20px;
}
body .footer .container .contactFoot {
  text-align: left;
}
@media (max-width: 800px) {
  body .footer .container .contactFoot {
    text-align: center;
  }
}
body .footer .container .contactFoot .grid {
  display: grid;
  grid-template-columns: 45px 1fr;
  justify-content: space-evenly;
  align-items: center;
  row-gap: 10px;
}
body .footer .container .contactFoot .grid svg {
  width: 35px;
  height: 35px;
}
body .footer .container .menu {
  text-align: right;
}
@media (max-width: 800px) {
  body .footer .container .menu {
    text-align: center;
  }
}
body .footer .container .menu a {
  text-decoration: none;
  color: #ffffff;
}
body .e404 {
  height: 60.25vh;
  padding: 15vh 0;
  box-sizing: border-box;
  width: 500px;
  margin: 0 auto;
  display: grid;
  align-items: center;
  grid-template-rows: 50px 50px min-content 50px;
  grid-template-columns: 100%;
  text-align: center;
}
body .e404 svg {
  width: 50px;
  height: 50px;
  justify-self: center;
  color: #8585f2;
}
body .e404 h1, body .e404 p {
  width: 100%;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 20px;
  height: 20px;
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 60.3vh;
}

