$background : #ffffff;
$border : #b5b5b5;
$fontMain : "Blinker", serif;
$fontSecond : "BlackHanSans", serif;
$fontColorMain : #000000;
$fontColorSecond : #8585f2;
$iconColorHeader : rgb(30,48,80);
$iconColorMain : #8585f2;
$iconColorSecond : #ffffff;

@font-face {
  font-family: "Blinker";
  src: url("../font/Blinker/Blinker-Thin.ttf");
  font-weight: 100;
}
@font-face {
  font-family: "Blinker";
  src: url("../font/Blinker/Blinker-ExtraLight.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "Blinker";
  src: url("../font/Blinker/Blinker-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Blinker";
  src: url("../font/Blinker/Blinker-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Blinker";
  src: url("../font/Blinker/Blinker-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Blinker";
  src: url("../font/Blinker/Blinker-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Blinker";
  src: url("../font/Blinker/Blinker-ExtraBold.ttf");
  font-weight: 800;
}
@font-face {
  font-family: "Blinker";
  src: url("../font/Blinker/Blinker-Black.ttf");
  font-weight: 900;
}
@font-face {
  font-family: "BlackHanSans";
  src: url("../font/Black_Han_Sans/BlackHanSans-Regular.ttf");
  font-weight: 400;
}

* {
  margin:0;
  padding: 0;
}
._rzr0w {
  position: fixed !important;
  z-index: 9999;
  svg {
    visibility: hidden;
  }
}
*::-webkit-scrollbar{
  width: 10px;
  background-color: #f5f5f5;
}
*::-webkit-scrollbar:horizontal{
  height: 8px;
}
*::-webkit-scrollbar-thumb {
  background-color: $border;
  border-radius: 20px;
  // background-image: -webkit-linear-gradient(45deg,hsla(0,0%,100%,.2) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.2) 0,hsla(0,0%,100%,.2) 75%,transparent 0,transparent);
}
*::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  border-radius: 20px;
  background-color: #f5f5f5;
}

body{
  background-color: $background;
  min-height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
  font-size: 18px;
  @media (max-width: 1250px) {
    font-size:14px;
  }
  @media (max-width: 1000px) {
    font-size:12px;
  }
  .header {
    width: 100%;
    border-bottom:solid 1px $border;
    box-sizing: border-box;
    font-size: 1.12em;
    .wrapper{
      width:67%;
      margin: 0 auto;
      text-align: center;
      .upper {
        height: 110px;
        display: grid;
        align-content: center;
        grid-template-columns: 40% 20% 40%;
        color: $iconColorHeader;
        @media (max-width: 800px) {
          grid-template-columns: 1% 49% 50%;
        }
        svg {
          height: 2em;
          margin: 0 auto;
          @media (max-width: 600px) {
            height: 1.5em;
          }
        }
        .search {
          height:50px;
          width: 100%;
          display: grid;
          align-content: center;
          grid-template-columns: 50px 85%;
          justify-content: start;
          overflow: hidden;
          input {
            background-color: transparent;
            border: none;
            font-family: $fontMain;
            font-size: 1.12em;
            width: 100%;
            opacity: 0;
            transition: opacity ease 1s;
            border-bottom: solid 1px $border;
            &:focus-visible {
              opacity: 1;
              outline: none;
            }
          }
        }
        .logo {
          height:50px;
          img {
            height: 100px;
            transform: translateY(-25%);
          }
        }
        .icons {
          height:50px;
          display: grid;
          align-content: center;
          justify-content: end;
          grid-template-columns: repeat(3,2.5em);
          
          .i {
            width: 100%;
            position: relative;
            user-select: none;
            cursor: pointer;
            &.cartIcon .counter {
              background: $fontColorSecond;
              border-radius: 50%;
              height: 20px;
              width: 20px;
              line-height: 18px;
              color: $background;
              font-size: 1em;
              position: absolute;
              bottom: -5px;
              right: 0;
            }
            .menu {
              position: absolute;
              width: fit-content;
              top: 110%;
              left: 50%;
              transform: translateX(-50%);
              border: solid 1px $border;
              z-index: 10;
              font-size: 0.8em;
              display: block;
              visibility: hidden;
              background: $background;
              a {
                height: 25px;
                width: 100%;
                display: grid;
                align-items: center;
                text-decoration: none;
                color: $fontColorMain;
                border-bottom: solid 1px $border;
                padding: 5px 10px;
                box-sizing: border-box;
                &:last-child {
                  border-bottom: none;
                }
                h3 {
                  text-align: center;
                  width: 100%;
                  font-weight: 400;
                  font-family: $fontMain;
                  font-size: 1em;
                  white-space: nowrap;
                }
              }

            }
            &.menuActive .menu {
              visibility: visible;
            }
          }
        }
      }
      .lower {
        font-family: $fontMain;
        border-top:solid 1px $border;
        box-sizing: border-box;
        height: 50px;
        display: grid;
        justify-content: center;
        align-content: center;
        nav > div {
          display: inline-block;
          border:none;
          margin: 0 1em;
          cursor: pointer;
          user-select: none;
          &.selector {
            position: relative;
            .selection {
              visibility: hidden;
              width: 100%;
              position: absolute;
              top: 100%;
              left: -10px;
              z-index: 10;
              background-color: $background;
              line-height: 30px;
              padding: 0 10px;
              a {
                border-top: solid 1px $fontColorSecond;
                &:first-child {
                  margin-top: 10px;
                }
              }
            }
            &.active .selection {
              visibility: visible;
            }
          }
          a {
            display: block;
            text-decoration: none;
            color: black;
            cursor: pointer;
          }
        }
      }
    }
  } 
  .home {
    .mainCaroussel {
      width:100%;
      height:820px;
      overflow: hidden;
      position: relative;
      margin-bottom: 20px;
      @media (max-width: 1250px) {
        height: 80vh;
      }
      .filter {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 10%;
        background-color: #000000;
        z-index: 5;
      }
      .container {
        height: 100%;
        width: 300%;
        position: relative;
        transition: transform linear 1s;
        .element {
          width: calc(100%/3);
          height: 100%;
          overflow: hidden;
          position: relative;
          display: inline-block;
          h4 {
            position: absolute;
            color:$iconColorSecond;
            z-index: 6;
            left: 200px;
            bottom: 120px;
            pre {
              font-family: $fontMain;
              font-weight: 800;
              font-size: 1.25em;
              text-align: center;
            }
            @media (max-width: 800px) {
              left: auto;
              width: 100%;
            }
          }
          &:first-of-type h4{
            bottom: 120px;
            left: auto;
            right: 200px;
            @media (max-width: 800px) {
              right: auto;
              width: 100%;
            }
          }
          &:last-of-type h4 {
            top:30px;
            left:500px;
            @media (max-width: 800px) {
              left: auto;
              width: 100%;
            }
          }
          img {
            width: 100vw;
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
            @media (max-width: 1250px) {
              width: auto;
              height: 100%;
            }
          }
        }
      }
    }
    .products {
      width: 100%;
      .container {
        width: 67%;
        margin: 0 auto;
        @media (max-width: 1250px) {
          width:100%;
        }
        h1 {
          text-align: center;
          width: 100%;
          height: 100px;
          line-height: 100px;
          font-family: $fontSecond;
          font-size: 2.7em;
          color: $fontColorSecond;
          margin-bottom: 10px; 
        }
        h4 {
          width: 100%;
          font-family: $fontMain;
          font-size: 1em;
          font-weight: 400;
          text-align: center;
          line-height: 2.5em;
        }
        h5 {
          width: 100%;
          font-family: $fontMain;
          font-size: 1em;
          font-weight: 400;
          text-align: center;
          line-height: 2em;
        }
        .cd {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(4,300px);
          justify-content: space-between;
          @media (max-width: 1250px) {
            grid-template-columns: repeat(4,24%);
            justify-content: space-evenly;
          }
          @media (max-width: 800px) {
            grid-template-columns: repeat(2,45%);
          }
          h1 {
            grid-column: span 4;
            @media (max-width: 800px) {
              grid-column: span 2;
            }
          }
          .element {
            width: 300px;
            @media (max-width: 1250px) {
              width: 100%;
            }
            .imageUnit {
              width: 300px;
              height: 300px;
              overflow: hidden;
              position: relative;
              background: #d9d9d9;
              @media (max-width: 1250px) {
                width: 100%;
              }
              img {
                position: absolute;
                top:50%;
                left:50%;
                transform: translate(-50%,-50%);
                width: 100%;
              }
            }
          }
        }
        .books {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3,400px);
          justify-content: space-between;
          @media (max-width: 1250px) {
            grid-template-columns: repeat(3,30%);
            justify-content: space-evenly;
          }
          @media (max-width: 1000px) {
            grid-template-columns: 80%;
          }
          h1 {
            grid-column: span 3;
            @media (max-width: 1000px) {
              grid-column: span 1;
            }
          }
          .element {
            width: 400px;
            @media (max-width: 1250px) {
              width: 100%;
            }
            .imageUnit {
              width: 400px;
              height: 550px;
              overflow: hidden;
              position: relative;
              background: #d9d9d9;
              @media (max-width: 1250px) {
                width: 100%;
                height: 80vh;
              }
              img {
                position: absolute;
                top:50%;
                left:50%;
                transform: translate(-50%,-50%);
                width: 100%;
                @media (max-width: 1250px) {
                  width: auto;
                  height: 100%;
                }
              }
            }
          }
        }
        .games {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2,600px);
          justify-content: space-between;
          @media (max-width: 1250px) {
            grid-template-columns: 80%;
            justify-content: space-evenly;
          }
          h1 {
            grid-column: span 2;
            @media (max-width: 1250px) {
              grid-column: span 1;
            }
          }
          .element {
            width: 600px;
            @media (max-width: 1250px) {
              width: 100%;
            }
            .imageUnit {
              width: 600px;
              height: 700px;
              overflow: hidden;
              position: relative;
              background: #d9d9d9;
              @media (max-width: 1250px) {
                width: 100%;
                height: 80vh;
              }
              img {
                position: absolute;
                top:50%;
                left:50%;
                transform: translate(-50%,-50%);
                width: 100%;
                @media (max-width: 1250px) {
                  width: auto;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
    .pinnedNumbers {
      width: 90%;
      margin: 0 auto;
      padding: 75px 0;
      display: grid;
      grid-template-columns: repeat(4,1fr);
      column-gap: 25px;
      text-align: center;
      @media (max-width: 1250px) {
        grid-template-columns: repeat(2,1fr);
        row-gap: 25px;
        font-size: 1.5em;
      }
      @media (max-width: 800px) {
        grid-template-columns: 1fr;
        font-size: 2em;
      }
      svg {
        height: 45px; 
        path {
          color: $iconColorMain;
        }
      }
      h2 {
        margin-top: 15px;
        margin-bottom: 5px;
        font-family: $fontSecond;
        font-size: 1em;
      }
      pre {
        font-family: $fontMain;
        font-size: 0.66em;
      }
    }
    .newsletter {
      position: relative;
      width: 100%;
      overflow: hidden;
      img {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
      .filter {
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 10%;
        z-index: 1;
      }
      .container {
        position: relative;
        z-index: 2;
        width: fit-content;
        background-color: $background;
        margin: 50px 150px 50px auto;
        padding: 20px 15px;
        border-radius: 20px;
        h2 {
          color: $fontColorSecond;
          font-family: $fontSecond;
          font-size: 1.5em;
          line-height: 2em;
        }
        pre {
          font-family: $fontMain;
          font-size: 0.9em;
          line-height: 2em;
        }
        input {
          height: 60px;
          width: 100%;
          background-color: #d9d9d9;
          border: none;
          border-radius: 10px;
          box-sizing: border-box;
          padding: 5px 10px;
          margin: 5px 0 10px 0;
          font-family: $fontMain;
          font-size: 0.9em;
        }
        button {
          width: 100%;
          height: 50px;
          font-size: 1.5em;
          font-family: $fontSecond;
          background-color: $fontColorSecond;
          color: $background;
          border: none;
        }
      }
    }
  }
  .product {
    min-height:60.3vh;
    width: 70%;
    padding: 50px 0;
    margin: 0 auto;
    @media (max-width: 1250px) {
      width: 100%;
      box-sizing: border-box;
      padding: 50px 10px;
    }
    .container {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      box-sizing: border-box;
      position: relative;
      column-gap: 50px;
      @media (max-width: 800px) {
        grid-template-columns: 90%;
        justify-content: space-around;
      }
      .images {
        height: fit-content;
        position: sticky;
        top:0;
        width: 100%;
        display: grid;
        overflow: hidden;
        grid-template-columns: repeat(2,calc(50% - 12.5px));
        column-gap: 20px;
        row-gap: 20px;
        & > img {
          grid-column: span 2;
        }
        img {
          width: 100%;
        }
        @media (max-width: 800px) {
          position:static;
        }
      }
      .infos {
        width: 100%;
        height: fit-content;
        box-sizing: border-box;
        padding: 20px 0;
        position: sticky;
        top:0;
        @media (max-width: 800px) {
          position: static;
        }
        h2 {
          font-family: $fontSecond;
          font-size: 2em;
          font-weight: 400;
        }
        .options {
          margin: 20px 0;
          display: grid;
          width: 100%;
          row-gap: 20px;
          grid-template-columns: 100%;
          box-sizing: border-box;
          h4 {
            font-size: 1.75em;
            font-family: $fontMain;
            font-weight: 400;
          }
          .optionType {
            width: 100%;
            &.BOOLEAN {
              height: 50px;
              width: 100%;
              display: grid;
              line-height: 50px;
              grid-template-columns: 50px 1fr;
              .option {
                width: 100%;
                text-align: center;
                order: -1;
                position: relative;
                input[type=checkbox] {
                  height: 35px;
                  width: 35px;
                  border-radius: 5px;
                  border-color: $background !important;
                  color: $background;
                  visibility: hidden;
                  &::before {
                    visibility: visible;
                    content: '';
                    display: inline-block;
                    width: 35px;
                    height: 35px;
                    border: 2px solid #d9d9d9;
                    border-radius: 5px;
                    box-sizing: border-box;
                  }
                  &.checked::before{
                    border: 2px solid $iconColorMain;
                  }
                  &.checked + svg {
                    visibility: visible;
                  }
                }
                svg {
                  height: 50px;
                  width: 50px;
                  position: absolute;
                  transform: translate(-50%,-50%);
                  top:35%;
                  left:55%;
                  color: $iconColorMain;
                  visibility: hidden;
                  pointer-events: none;
                }
                
              }
            }
            .option {
              font-size: 1.75em;
              font-family: $fontMain;
              display: inline-block;
              width: 75px;
              height: 75px;
              border-radius: 5px;
              box-sizing: border-box;
              text-align: center;
              line-height: 75px;
              margin-right: 10px;
              overflow: hidden;
              &.TEXT {
                background: #d9d9d9 !important;
              }
              &.TEXT.selected {
                background: $iconColorMain !important;
              }
              &.selected {
                border: solid 10px $iconColorMain;
                line-height: 55px;
                color:$background;
              }
              &.noStock {
                background: linear-gradient(45deg, #b9b9b9 12.5%, #d9d9d9 12.5%, #d9d9d9 37.5%, #b9b9b9 37.5%, #b9b9b9 62.5%, #d9d9d9 62.5%, #d9d9d9 87.5%, #b9b9b9 87.5%) !important;
                color: #d9d9d9;
              }
              &.noStock.selected {
                border: none;
                line-height: 75px;
              }
            }
          }
        }
        .quantity {
          background-color: #d9d9d9;
          width: 250px;
          display: grid;
          height: 100px;
          grid-template-columns: repeat(3,1fr);
          justify-content: center;
          align-content: center;
          text-align: center;
          font-family: $fontMain;
          font-size: 2em;
          line-height: 100px;
          border-radius: 20px;
          button {
            background: transparent;
            border: none;
            font-family: $fontMain;
            font-size: 1.5em;
            text-align: left;
            &:first-of-type {
              text-align: right;
            }
          }
        }
        & > button {
          height: 100px;
          width: 100%;
          background: $fontColorSecond;
          color:$background;
          border: none;
          font-family: $fontSecond;
          font-size: 1.8em;
          margin-bottom: 40px;
        }
        p {
          font-size: 1.5em;
          font-family: $fontMain;
          margin-bottom: 40px;
        }
        .selector {
          width: 250px;
          height: 75px;
          position: relative;
          z-index: 11;
          .title {
            width: 250px;
            height: 75px;
            display: grid;
            grid-template-columns: 1fr 50px;
            text-align: center;
            justify-content: center;
            line-height: 75px;
            align-items: center;
            border: solid 1px $fontColorSecond;
            box-sizing: border-box;
            border-radius: 20px;
            z-index: 11;
            position: relative;
            background-color: $background;
            svg {
              height: 30px;
              width: 30px;
            }
          }
          h4 {
            font-size: 1.5em;
            font-weight: 400;
            font-family: $fontMain;
          }
          .selection {
            position: absolute;
            visibility: hidden;
            left: 0;
            top: 100%;
            z-index: 9;
            width: 250px;
            line-height: 75px;
            text-align: center;
            background-color: $background;
            border: solid 1px $iconColorMain;
            box-sizing: border-box;
            padding-top: 20px;
            margin-top: -20px;
            h4 {
              padding-right: 50px;
              border-bottom: solid 1px $iconColorMain;
              box-sizing: border-box;
            }
          }
          &.active > .selection {
            visibility: visible;
          }
        }
      }
    }
  }
  .productList {
    width: 100%;
    padding-top: 25px;
    .container {
      width: 80%;
      position: relative;
      margin: 0 auto;
      text-align: center;
      box-sizing: border-box;
      @media (max-width: 1250px) {
        width: 100%;
        padding: 0 25px;
      }
      h1 {
        font-size: 2.1em;
        font-family: $fontSecond;
        color:$fontColorSecond;
        height: 75px;
        line-height: 75px;
      }
      .actionBar {
        width: 100%;
        .selector {
          width: 250px;
          height: 35px;
          position: relative;
          margin: 0 0 0 auto;
          z-index: 11;
          .title {
            width: 250px;
            height: 35px;
            display: grid;
            grid-template-columns: 1fr 50px;
            text-align: center;
            justify-content: center;
            line-height: 35px;
            align-items: center;
            border: solid 1px $fontColorSecond;
            box-sizing: border-box;
            border-radius: 20px;
            z-index: 11;
            position: relative;
            background-color: $background;
            svg {
              height: 30px;
              width: 30px;
            }
          }
          h4 {
            font-size: 0.95em;
            font-weight: 400;
            font-family: $fontMain;
          }
          .selection {
            position: absolute;
            visibility: hidden;
            left: 0;
            top: 100%;
            z-index: 9;
            width: 250px;
            line-height: 35px;
            text-align: center;
            background-color: $background;
            border: solid 1px $iconColorMain;
            box-sizing: border-box;
            padding-top: 20px;
            margin-top: -20px;
            overflow: hidden;
            h4 {
              padding-right: 50px;
              border-bottom: solid 1px $iconColorMain;
              box-sizing: border-box;
            }
          }
          &.active > .selection {
            visibility: visible;
          }
        }
      }
      .grid {
        margin-top: 25px;
        margin-bottom: 75px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        column-gap: 25px;
        row-gap: 75px;
        @media (max-width: 1250px) {
          grid-template-columns: repeat(2,1fr);
        }
        @media (max-width: 800px) {
          grid-template-columns: 1fr;
        }
        .productUnit {
          width: 100%;
          display: block;
          overflow: hidden;
          text-decoration: none;
          color: none;
          .pic {
            position: relative;
            width: 100%;
            height: 650px;
            background: #d9d9d9;
            overflow: hidden;
            img {
              width: 100%;
              position: absolute;
              top:50%;
              left: 50%;
              transform: translate(-50%,-50%);
            }
          }
          .name {
            font-family: $fontSecond;
            color:$fontColorSecond;
            font-size: 1.4em;
            font-weight: 400;
            line-height: 50px;
          }
          .price {
            font-family: $fontMain;
            color: $fontColorMain;
            font-size: 1.2em;
            font-weight: 400;
          }
        }
      }
    }
    
  }
  .contact {
    display: grid;
    width: 80%;
    margin: 0 auto;
    grid-template-columns: 25% 75%;
    padding: 55px 0;
    box-sizing: border-box;
    @media (max-width: 1250px) {
      width: 100%;
      padding: 55px 25px;
    }
    @media (max-width: 800px) {
      grid-template-columns: 1fr;
    }
    .infos {
      height: 100%;
      border-right: solid 1px $border;
      display: grid;
      align-items: center;
      @media (max-width: 800px) {
        border-right: none;
        border-bottom: solid 1px $border;
        padding: 10px 0;
      }
      .grid {
        width: 100%;
        display: grid;
        grid-template-columns: 65px min-content;
        grid-auto-rows: min-content;
        align-items: center;
        row-gap: 25px;
        font-size: 1.2em;
        font-family: $fontMain;
        @media (max-width: 1250px) {
          justify-content: center;
        }
        svg {
          width: 50px;
          height: 50px;
          color: $iconColorMain;
        }
      }
    }
    .form {
      .container {
        padding: 34.5px 20px;
        .formUnit {
          textarea {
            display: block;
            width: 100%;
            height: 200px;
            box-sizing: border-box;
            padding: 5px 20px;
            border-radius: 5px;
            border: solid 1px $border;
            margin-top: 10px;
            font-size: 1.2em;
            resize: none;
            overflow-y: auto;
            overflow-x: none;
          }
        }
      }
    }
  }
  .account {
    width: 80%;
    margin: 0 auto;
    min-height: 49.3vh;
    padding: 50px 50px;
    display: grid;
    grid-template-columns: 20% 80%;
    justify-content: center;
    align-items: start;
    position: relative;
    box-sizing: border-box;
    @media (max-width: 1250px) {
      width:100%;
      padding: 50px 10px;
    }
    @media (max-width: 800px) {
      grid-template-columns: 1fr;
    }
    .accountNav {
      border-right: solid 1px $border;
      height: 580px;
      width: 100%;
      display: grid;
      grid-template-rows: 85% 15%;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: sticky;
      top: 50px;
      @media (max-width: 800px) {
        height: min-content;
        grid-template-rows: 1fr;
        grid-template-columns: 75% 25%;
        position: static;
        border-right: none;
        border-bottom: solid 1px $border;
      }
      a {
        width: 100%;
        display: block;
        font-family: $fontMain;
        font-size: 1em;
        color: $border;
        line-height: 2em;
        text-decoration: none;
        cursor: pointer;
        &.active {
          color: $fontColorSecond;
        }
        @media (max-width: 800px) {
          display: inline-block;
          width: auto;
          padding: 5px 10px;
        }
      }
      button {
        width: 260px;
        height: 50px;
        border: none;
        background: none;
        position: relative;
        font-family: $fontMain;
        font-size: 1em;
        color: $border;
        cursor: pointer;
        &::after {
          content: "";
          height: 1px;
          width: 150px;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          border-bottom: solid 1px $border;
        }
        @media (max-width: 800px) {
          width: auto;
          padding: 5px 10px;
          &::after {
            visibility: hidden;
          }
        }
      }
    }
    .form {
      .container {
        padding: 50px 20px;
        .formUnit {
          position: relative;
          h3 {
            font-family: $fontMain;
            font-weight: 400;
            font-size: 1em;
            user-select: none;
          }
          &.BOOLEAN {
            height: 50px;
            width: 100%;
            display: grid;
            line-height: 50px;
            grid-template-columns: 50px 1fr;
            text-align: left;
            .boolContainer {
              width: 100%;
              position: relative;
              order: -1;
              text-align: center;
              input[type=checkbox] {
                height: 35px;
                width: 35px;
                border-radius: 5px;
                border-color: $background !important;
                color: $background;
                visibility: hidden;
                &::before {
                  visibility: visible;
                  content: '';
                  display: inline-block;
                  width: 35px;
                  height: 35px;
                  border: 2px solid #d9d9d9;
                  border-radius: 5px;
                  box-sizing: border-box;
                }
                &.checked::before{
                  border: 2px solid $iconColorMain;
                }
                &.checked + svg {
                  visibility: visible;
                }
              }
              svg {
                height: 50px;
                width: 50px;
                position: absolute;
                transform: translate(-50%,-50%);
                top:35%;
                left:55%;
                color: $iconColorMain;
                visibility: hidden;
                pointer-events: none;
                background: transparent;
              }
            }
          }
        }
      }
    }
    .orders {
      height: 580px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0px 75px;
      box-sizing: border-box;
      .order {
        height: 75px;
        display: grid;
        grid-template-columns: 28% 28% 28% 16%;
        text-align: center;
        justify-content: center;
        font-family: $fontMain;
        font-weight: 400;
        font-size: 1em;
        .status {
          font-family: $fontSecond;
          &.COMPLETED {
            color: #2fa12f;
          }
          &.PAID, &.PENDING {
            color: #ffa500;
          }
          &.CANCELED {
            color: #db0000;
          }
        }
      }
    }
    .supports {
      height: 580px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0px 75px;
      box-sizing: border-box;
      .support {
        height: 75px;
        display: grid;
        grid-template-columns: 28% 28% 28% 16%;
        text-align: center;
        justify-content: center;
        font-family: $fontMain;
        font-weight: 400;
        font-size: 1em;
        .status {
          font-family: $fontSecond;
          &.PENDING {
            color: #2fa12f;
          }
          &.ANSWERED {
            color: #ffa500;
          }
          &.CLOSED {
            color: #b5b5b5;
          }
        }
      }
    }
  }
  .supportPage {
    width: 80%;
    margin: 0 auto;
    min-height: 49.3vh;
    height: min-content;
    max-height: 75vh;
    padding: 50px 50px;
    display: grid;
    grid-template-columns: 20% 80%;
    justify-content: center;
    align-items: start;
    position: relative;
    box-sizing: border-box;
    @media (max-width: 800px) {
      width: 100%;
      max-height: max-content;
      grid-template-columns: 100%;
    }
    .supportNav {
      border-right: solid 1px $border;
      height: 580px;
      width: 100%;
      position: sticky;
      top: 50px;
      z-index: 5;
      @media (max-width: 800px) {
        position: absolute;
        top: 50px;
        left: 0;
      }
      .burger {
        position: absolute;
        top: 50%;
        left: 5px;
        font-size: 2em;
        transform: translateY(-50%);
        visibility: hidden;
        @media (max-width: 800px) {
          visibility: visible;
        }
      }
      .close {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 2em;
        transform: translateY(-50%);
        visibility: hidden;
      }
      .container {
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 90% 10%;
        justify-content: center;
        text-align: center;
        background-color: $background;
        @media (max-width: 800px) {
          visibility: hidden;
        }
        nav {
          overflow-x: hidden;
          overflow-y: auto;
          box-sizing: border-box;
          padding-right: 5px;
          a {
            width: 100%;
            height: 100px;
            display: grid;
            font-family: $fontMain;
            font-size: 1em;
            color: $fontColorMain;
            line-height: 2em;
            text-decoration: none;
            cursor: pointer;
            grid-template-rows: 38% 38% 24%;
            box-sizing: border-box;
            border: solid 1px $background;
            border-radius: 5px;
            text-align: left;
            box-sizing: border-box;
            padding: 10px;
            margin-bottom: 20px;
            h3 {
              font-size: 1.2em;
              font-weight: 700;
              font-family: $fontMain;
            }
            .small {
              display: grid;
              grid-template-columns: 50% 50%;
              h6 {
                font-family: $fontMain;
                font-weight: 300;
                font-style: italic;
                font-size: 0.75em;
              }
            }
            &:hover{
              border-color: $fontColorSecond;
              h3 {
                color: $fontColorSecond;
              }
              .small {
                h6 {
                  color: $fontColorSecond;
                }
              }
            }
            &.active {
              color: $background;
              background-color: $fontColorSecond;
              &:hover {
                h3 {
                  color: $background;
                }
                .small {
                  h6 {
                    color: $background;
                  }
                }
              }
            }
          }
        } 
        button {
          width: 260px;
          height: 50px;
          background: none;
          position: relative;
          font-family: $fontMain;
          font-size: 1.6em;
          color: $fontColorSecond;
          cursor: pointer;
          border: solid 1px $fontColorSecond;
          font-weight: 600;
          border-radius: 5px;
          align-self: end;
          &:hover {
            background-color: $fontColorSecond;
            color: $background;
          }
        }
      }
      &.active {
        @media (max-width: 800px) {
          .container {
            visibility: visible;
          }
          .close {
            visibility: visible;
          }
          .burger {
            visibility: hidden;
          }
        }
      }
    }
    .supportRequest {
      height: 580px;
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      .container {
        height: 100%;
        width: 100%;
        display: grid;
        box-sizing: border-box;
        padding-bottom: 75px;
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        @media (max-width: 800px) {
          grid-template-rows: 1fr min-content;
          padding-bottom: 0;
        }
        h1 {
          grid-row: span 3;
          align-self: center;
          text-align: center;
          color: $border;
        }
        .messages {
          height: 107%;
          width: 100%;
          overflow-x: hidden;
          overflow-y: auto;
          display: grid;
          grid-auto-rows: min-content;
          grid-template-columns: 100%;
          row-gap: 15px;
          padding: 10px 20px 5% 50px;
          box-sizing: border-box;
          @media (max-width: 800px) {
            padding: 10px 0;
          }
          .message {
            height: fit-content;
            max-width: 80%;
            width: fit-content;
            position: relative;
            border: solid 1px $border;
            border-radius: 5px;
            padding: 10px 20px;
            h5 {
              position: absolute;
              left: 20px;
              top: -8px;
              background: $background;
              padding: 0 5px;
              font-weight: 400;
              font-family: $fontMain;
              font-size: 0.83em;
            }
            h6 {
              font-family: $fontMain;
              font-weight: 200;
              text-align: right;
            }
            &.user {
              border-color: $fontColorSecond;
              justify-self: end;
              text-align: right;
              h5 {
                left: auto;
                right: 20px;
                color: $fontColorSecond;
              }
              h6 {
                text-align: left;
              }
            }
          }
        }
        .sendBox {
          position: absolute;
          bottom: 0;
          right: 10px;
          display: grid;
          grid-auto-flow: column;
          @media (max-width: 800px) {
            right: 0;
            position: static;
          }
          .formUnit {
            height: 85px;
            width: 100%;
            position: relative;
            box-sizing: border-box;
            padding-top: 10px;
            @media (max-width: 800px) {
              width: auto;
            }
            textarea {
              display: block;
              width: 350px;
              height: 75px;
              box-sizing: border-box;
              padding: 5px 20px;
              border-radius: 5px;
              border: solid 1px $border;
              font-size: 1.2em;
              resize: none;
              overflow-y: auto;
              overflow-x: none;
              outline: none;
              font-family: $fontMain;
              @media (max-width: 800px) {
                width:250px
              }
              &:focus-visible {
                border-color: $fontColorSecond;
                border-width: 2px;
              }
            }
            h5 {
              position: absolute;
              left: 20px;
              top: 2px;
              background: $background;
              padding: 0 5px;
              font-weight: 400;
              font-family: $fontMain;
              font-size: 0.83em;
            }
          }
          button {
            width: 200px;
            height: 75px;
            margin-top: 10px;
            margin-left: 10px;
            position: relative;
            font-family: $fontMain;
            font-size: 1.6em;
            background-color: $background;
            color: $fontColorSecond;
            cursor: pointer;
            border: solid 1px $fontColorSecond;
            font-weight: 600;
            border-radius: 5px;
            align-self: end;
            @media (max-width: 800px) {
              width: 100px;
              align-self: auto;
            }
            &:hover {
              background-color: $fontColorSecond;
              color: $background;
            }
            .spinner-container {
              height: 100%;
            }
          }
        }
      }
      .form {
        height: 100%;
        display: grid;
        align-items: center;
        .container {
          width: 450px;
          padding: 0;
          overflow: visible;
          height: fit-content;
          .formUnit {
            position: relative;
            box-sizing: border-box;
            .selector {
              height: 50px;
              position: relative;
              margin-top: 10px;
              border: solid 1px $border;
              border-radius: 5px;
              font-size: 1.2em;
              .title {
                height: 100%;
                line-height: 45px;
                user-select: none;
                cursor: pointer;
              }
              .selection {
                visibility: hidden;
                width: 100%;
                left: -2px;
                position: absolute;
                top: 100%;
                z-index: 10;
                background-color: $background;
                line-height: 30px;
                border: solid 2px $border;
                user-select: none;
                cursor: pointer;
                div {
                  border-top: solid 1px $fontColorSecond;
                  &:first-child {
                    border-top: none;
                  }
                }
              }
              &.active .selection {
                visibility: visible;
              }
            }
            input {
              outline: none;
              &:focus-visible {
                border-color: $fontColorSecond;
                border-width: 1px;
              }
            }
            textarea {
              display: block;
              margin-top: 10px;
              width: 100%;
              height: 150px;
              box-sizing: border-box;
              padding: 5px 20px;
              border-radius: 5px;
              border: solid 1px $border;
              font-size: 1.2em;
              resize: none;
              overflow-y: auto;
              overflow-x: none;
              outline: none;
              font-family: $fontMain;
              &:focus-visible {
                border-color: $fontColorSecond;
                border-width: 1px;
              }
            }
          }
        }
      }
    }
  }
  .order {
    width: 100%;
    height: 100%;
    position: relative;
    .filter {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #d9d9d9;
      opacity: 0.4;
      z-index: 99;
    }
    .container {
      position: relative;
      margin: 0 auto;
      z-index: 100;
      background: $background;
      width: 80%;
      height: 100%;
      padding: 0 25px;
      box-sizing: border-box;
      .cartHeader {
        height: 120px;
        width: 100%;
        box-sizing: border-box;
        padding-top: 40px;
        position: relative;
        h1 {
          font-family: $fontSecond;
          color: $fontColorSecond;
          font-weight: 400;
        }
        svg {
          position: absolute;
          top:20px;
          right: 0px;
          height: 40px;
          color: $fontColorSecond;
          cursor: pointer;
        }
      }
      .cartBody {
        height: calc(100% - 240px);
        width: 100%;
        box-sizing: border-box;
        border-top: solid 1px $iconColorMain;
        border-bottom: solid 1px $iconColorMain;
        overflow: auto;
        padding: 20px 0;
        .productUnit {
          display: grid;
          grid-template-columns: 25% 75%;
          margin-bottom: 30px;
          .pic {
            width: 100%;
            position: relative;
            display: grid;
            justify-content: center;
            align-items: center;
            img {
              width: 100%;
            }
          }
          .infos {
            width: 100%;
            display: grid;
            grid-template-columns: 75% 25%;
            align-items: center;
            box-sizing: border-box;
            padding: 10px 0 0 20px;
            .nom {
              font-size: 1.2em;
              font-family: $fontMain;
              font-weight: 400;
            }
            .price {
              font-size: 1.2em;
              font-family: $fontMain;
              font-weight: 400;
              font-style: italic;
            }
            .subinfo {
              h4 {
                font-size: 1em;
                font-family: $fontMain;
                font-weight: 200;
                font-style: italic;
              }
            }
            svg {
              color: $fontColorSecond;
              height: 30px;
              justify-self: center;
              cursor: pointer;
            }
          }
        }
      }
      .cartFooter {
        height: 120px;
        width: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 80% 20%;
        line-height: 40px;
        align-items: center;
        align-content: start;
        grid-template-rows:min-content;
        h3 {
          font-size: 1.2em;
          font-weight: 400;
          font-family: $fontMain;
          &:last-of-type {
            text-align: right;
          }
          &:first-of-type {
            text-align: left;
          }
        }
        .spinner-container {
          max-height: 40px;
        }
        button {
          grid-column: 2 span;
          height: 50px;
          background-color: $fontColorSecond;
          color: $background;
          font-family: $fontSecond;
          border: none;
          font-size: 1.5em;
        }
      }
    }
  }
  .cart {
    width: 100%;
    height: 100%;
    position: fixed;
    top:0;
    left: 0;
    z-index: 100;
    visibility: hidden;
    &.active {
      visibility: visible;
    }
    .filter {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #d9d9d9;
      opacity: 0.4;
      z-index: 99;
    }
    .container {
      position: relative;
      margin: 0 0 0 auto;
      z-index: 100;
      background: $background;
      width: 500px;
      height: 100%;
      padding: 0 25px;
      box-sizing: border-box;
      .cartHeader {
        height: 120px;
        width: 100%;
        box-sizing: border-box;
        padding-top: 40px;
        position: relative;
        h1 {
          font-family: $fontSecond;
          color: $fontColorSecond;
          font-weight: 400;
        }
        svg {
          position: absolute;
          top:20px;
          right: 0px;
          height: 40px;
          color: $fontColorSecond;
          cursor: pointer;
        }
      }
      .cartBody {
        height: calc(100% - 240px);
        width: 100%;
        box-sizing: border-box;
        border-top: solid 1px $iconColorMain;
        border-bottom: solid 1px $iconColorMain;
        overflow: auto;
        padding: 20px 0;
        .productUnit {
          display: grid;
          grid-template-columns: 25% 75%;
          margin-bottom: 30px;
          .pic {
            width: 100%;
            position: relative;
            display: grid;
            justify-content: center;
            align-items: center;
            img {
              width: 100%;
            }
          }
          .infos {
            width: 100%;
            display: grid;
            grid-template-columns: 75% 25%;
            align-items: center;
            box-sizing: border-box;
            padding: 10px 0 0 20px;
            .nom {
              font-size: 1.2em;
              font-family: $fontMain;
              font-weight: 400;
            }
            .price {
              font-size: 1.2em;
              font-family: $fontMain;
              font-weight: 400;
              font-style: italic;
            }
            .subinfo {
              h4 {
                font-size: 1em;
                font-family: $fontMain;
                font-weight: 200;
                font-style: italic;
              }
            }
            svg {
              color: $fontColorSecond;
              height: 30px;
              justify-self: center;
              cursor: pointer;
            }
          }
        }
      }
      .cartFooter {
        height: 120px;
        width: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 80% 20%;
        line-height: 40px;
        align-items: center;
        align-content: start;
        grid-template-rows:min-content;
        h3 {
          font-size: 1.2em;
          font-weight: 400;
          font-family: $fontMain;
          &:last-of-type {
            text-align: right;
          }
          &:first-of-type {
            text-align: left;
          }
        }
        .spinner-container {
          max-height: 40px;
        }
        button {
          grid-column: 2 span;
          height: 50px;
          background-color: $fontColorSecond;
          color: $background;
          font-family: $fontSecond;
          border: none;
          font-size: 1.5em;
        }
      }
    }
  }
  .form {
    width: 100%;
    text-align: center;
    .container {
      width: 500px;
      margin: 0 auto;
      display: grid;
      row-gap: 25px;
      box-sizing: border-box;
      padding: 150px 20px;
      h1 {
        font-family: $fontSecond;
        font-weight: 400;
        color: $fontColorSecond;
        line-height: 50px;
        font-size: 2.3em;
      }
      .formUnit {
        width: 100%;
        position: relative;
        input {
          width: 100%;
          height: 50px;
          box-sizing: border-box;
          padding: 5px 20px;
          border-radius: 5px;
          border: solid 1px $border;
          margin-top: 10px;
          font-size: 1.2em;
          font-family: $fontMain;
        }
        h5 {
          position: absolute;
          left: 20px;
          top: 2px;
          background: $background;
          padding: 0 5px;
          font-weight: 400;
          font-family: $fontMain;
          font-size: 0.83em;
        }
        h6 {
          font-weight: 400;
          font-size: 0.75em;
          text-align: left;
        }
        &.small {
          display: grid;
          grid-template-columns: 50% 50%;
          text-align: left;
          justify-content: start;
          align-items: start;
          h6 {
            font-weight: 400;
            font-size: 0.75em;
          }
          >*:last-child {
            text-align: end;
            color: $fontColorMain;
            font-weight: 400;
          }
        }
      }
      button {
        height: 50px;
        line-height: 50px;
        font-family: $fontMain;
        font-weight: 600;
        font-size: 1.5em;
        background-color: transparent;
        color: $fontColorSecond;
        border: solid 1px $fontColorSecond;
        border-radius: 5px;
        user-select: none;
        cursor: pointer;
        &:hover {
          background-color: $fontColorSecond;
          color: $background;
        }
      }
    }
  }
  .preferences {
    .cartBody {
      display: grid;
      grid-template-rows: calc(calc(100% - 2px) / 2) 2px calc(calc(100% - 2px) / 2) ;
      .languages {
        height:100%;
        width: 100%;
        margin-bottom: 20px;
        box-sizing: border-box;
        overflow: auto;
        display: grid;
        row-gap: 15px;
        column-gap: 15px;
        grid-auto-rows: min-content;
        grid-template-columns: repeat(4,1fr);
        .localeUnit {
          width: 100px;
          height: 50px;
          box-sizing: border-box;
          border: solid 1px $fontColorSecond;
          border-radius: 5px;
          padding: 7px 5px;
          overflow: hidden;
          display: grid;
          grid-template-columns: 55% 45%;
          align-items: center;
          text-align: center;
          font-size: 1.5em;
          font-family: $fontMain;
          user-select: none;
          h4 {
            font-weight: 400;
            line-height: 34px;
          }
          img {
            width: 100%;
            height: 100%;
          }
          &.active {
            background-color: $fontColorSecond;
            font-family: $fontSecond;
            color: $background;
            line-height: 34px;
          }
        }
      }
      .divide {
        border-bottom: solid 1px $iconColorMain;
      }
      .currencies {
        height:100%;
        width: 100%;
        box-sizing: border-box;
        margin-top: 20px;
        overflow: auto;
        display: grid;
        row-gap: 15px;
        column-gap: 15px;
        grid-auto-rows: min-content;
        grid-template-columns: repeat(4,1fr);
        .currencyUnit {
          width: 100px;
          height: 50px;
          box-sizing: border-box;
          border: solid 1px $fontColorSecond;
          border-radius: 5px;
          padding: 7px 5px;
          overflow: hidden;
          display: grid;
          grid-template-columns: 25% 75%;
          align-items: center;
          text-align: center;
          font-size: 1.5em;
          font-family: $fontMain;
          user-select: none;
          h4 {
            font-weight: 400;
            line-height: 34px;
          }
          &.active {
            background-color: $fontColorSecond;
            font-family: $fontSecond;
            color: $background;
            line-height: 34px;
          }
        }
      }
    }
    .cartFooter {
      padding-top: 40px;
    }
  }
  .footer {
    background-color: #131313;
    color: #ffffff;
    text-align: center;
    h2 {
      color: $fontColorSecond;
      font-family: $fontSecond;
      font-size: 1em;
      font-weight: 400;
      a {
        color: $fontColorSecond;
        text-decoration: none;
      }
    }
    .container {
      padding: 10px 50px;
      display: grid;
      justify-content: space-between;
      grid-template-columns: 1fr 1fr;
      font-family: $fontMain;
      font-size: 1em;
      line-height: 1.5em;
      @media (max-width: 800px) {
        grid-template-columns: 80%; 
        row-gap: 25px;
        justify-content: space-around;
      }
      h2 {
        margin-bottom: 20px;
      }
      .contactFoot {
        text-align: left;
        @media (max-width: 800px) {
          text-align: center;
        }
        .grid {
          display: grid;
          grid-template-columns: 45px 1fr;
          justify-content: space-evenly;
          align-items: center;
          row-gap: 10px;
          svg {
            width: 35px;
            height: 35px;
          }
        }
      }
      .menu {
        text-align: right;
        @media (max-width: 800px) {
          text-align: center;
        }
        a {
          text-decoration: none;
          color: #ffffff;
        }
      }
    }
  }
  .e404 {
    height: 60.25vh;
    padding: 15vh 0;
    box-sizing: border-box;
    width: 500px;
    margin: 0 auto;
    display: grid;
    align-items: center;
    grid-template-rows: 50px 50px min-content 50px;
    grid-template-columns: 100%;
    text-align: center;
    svg {
      width: 50px;
      height: 50px;
      justify-self: center;
      color:$fontColorSecond;
    }
    h1,p {
      width: 100%;
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 20px;
  height: 20px;
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 60.3vh;
}